const get = (data, key, defaultValue=undefined) => {
    if (data != null && key in data && data[key] != null) return data[key];
    return defaultValue;
}

const if_present = (value, defaultValue) => {
    if (value.trim().length === 0) return defaultValue;
    return value;
}

class Surgery {
    constructor(data) {
        this.surgery = {
            "id": get(data, "surgery_id", -1),
            "date": get(data, "surgery_date", ""),
            "time": get(data, "surgery_time", ""),
            "left_eye": get(data, "surgery_left_eye", false),
            "anaesthetist": get(data, "surgery_anaesthetist", ""),
            "anaesthetic": get(data, "surgery_anaesthetic", "local"),
            "billing_codes": get(data, "surgery_billing_codes", ""),
            "gap": get(data, "surgery_gap", true),
        };
        this.postop = {
            "clinician_name": if_present(`${get(data, "postop_clinician_salutation", "")} ${get(data, "postop_clinician_given_names", "")} ${get(data, "postop_clinician_surname", "")}`, "Ms Stephanie Callisto"),
            "clinic_name": get(data, "postop_clinic_name", "Pennington Eye Clinic"),
            "date": get(data, "surgery_postop_date", ""),
            "time": get(data, "surgery_postop_time", ""),
        };
        this.patient = {
            "id": get(data, "patient_id", -1),
            "given_names": get(data, "patient_given_names", ""),
            "dob": get(data, "patient_dob", ""),
            "health_fund": get(data, "patient_health_fund", "Self-funding"),
            "surname": get(data, "patient_surname", ""),
            "salutation": get(data, "patient_salutation", "Ms"),
            "male": get(data, "patient_male", true),
            "concession": get(data, "patient_concession", false),
        };
        this.clinic = {
            "name": get(data, "clinic_name", "Adelaide Day Surgery"),
        };
        this.clinician = {
            "name": if_present(`${get(data, "clinician_salutation", "")} ${get(data, "clinician_given_names", "")} ${get(data, "clinician_surname", "")}`, "A Prof Weng Chan"),
        };
        this.procedure = {
            "cataract": get(data, "procedure_cataract", false),
            "conjunctiva": get(data, "procedure_conjunctiva", false),
            "enucleation": get(data, "procedure_enucleation", false),
            "epiretinal_membrane": get(data, "procedure_epiretinal_membrane", false),
            "floaterectomy": get(data, "procedure_floaterectomy", false),
            "macular_hole": get(data, "procedure_macular_hole", false),
            "pterygium": get(data, "procedure_pterygium", false),
            "retinal_detachment": get(data, "procedure_retinal_detachment", false),
            "vitrectomy": get(data, "procedure_vitrectomy", false),
        };
        this.client_id = get(data, "client_id", -1);
    }

    static createSample() {
        return new Surgery({
            "surgery_date": "2025-01-01",
            "surgery_time": "10:00",
            "surgery_left_eye": true,
            "surgery_anaesthetist": "Dr James Dowling",
            "surgery_anaesthetic": "local",
            "surgery_billing_codes": "|Phaco + IOL (42702)|IVTA (42740)",
            "surgery_gap": true,
        
            "postop_clinician_salutation": "Ms",
            "postop_clinician_given_names": "Stephanie",
            "postop_clinician_surname": "Callisto",

            "postop_clinic_name": "Pennington Eye Clinic",
            "surgery_postop_date": "2025-01-02",
            "surgery_postop_time": "13:00",
        
            "patient_given_names": "John",
            "patient_dob": "1970-06-01",
            "patient_surname": "Doe",
            "patient_salutation": "Mr",
            "patient_male": true,
            "patient_concession": false,
        
            "clinic_name": "Adelaide Day Surgery",
        
            "clinician_salutation": "A Prof",
            "clinician_given_names": "Weng",
            "clinician_surname": "Chan",
        
            "procedure_cataract": true,
            "procedure_conjunctiva": true,
        });
    }
}

export default Surgery;
