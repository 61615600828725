import {
    surgeries_getHeader,
    surgeries_getTitle,
    surgeries_fullPageImage
} from "../utils";
import {surgeries_postOpInfo_faceDownRecovery_img} from "../img/dropSheet";

export function surgeries_postOpInfoForm(doc, data) {
    data.procedure.cataract && surgeries_postOpInfo_cat(doc);
    data.procedure.conjunctiva && surgeries_postOpInfo_cp(doc, "Conjunctival / Biopsy Surgery");
    data.procedure.epiretinal_membrane && surgeries_postOpInfo_verm(doc, "Epiretinal Membrane Surgery");
    data.procedure.floaterectomy && surgeries_postOpInfo_verm(doc, "Vitrectomy");
    data.procedure.macular_hole && surgeries_postOpInfo_verm(doc, "Macular Hole Surgery");
    data.procedure.pterygium && surgeries_postOpInfo_cp(doc, "Pterygium Surgery");
    data.procedure.retinal_detachment && surgeries_postOpInfo_verm(doc, "Retinal Detachment Surgery");
    data.procedure.vitrectomy && surgeries_postOpInfo_verm(doc, "Vitrectomy");
}

// Post-Op Info for Vitrectomy, Epiretinal Membrane, Retinal Detachment, Macular Hole
function surgeries_postOpInfo_verm(doc, title) {
    doc.content.push([
        surgeries_getHeader(true),
        surgeries_getTitle(`${title} - Post-Operative Information`),
        surgeries_postOpInfo_dayOfSurgery(),
        surgeries_postOpInfo_afterTheSurgery_verm(),
        surgeries_postOpInfo_instructionsToFollow_verm(),
        surgeries_postOpInfo_symptomsToWatchFor(),
    ]);
    if (title.toLowerCase().includes("macular hole")) surgeries_postOpInfo_faceDownRecovery(doc)
}

// Post-Op Info for Conjunctiva and Pterygium
function surgeries_postOpInfo_cp(doc, title) {
    doc.content.push([
        surgeries_getHeader(true),
        surgeries_getTitle(`${title} - Post-Operative Information`),
        surgeries_postOpInfo_dayOfSurgery(),
        surgeries_postOpInfo_afterTheSurgery_cp(),
        surgeries_postOpInfo_instructionsToFollow_cp(),
        surgeries_postOpInfo_symptomsToWatchFor(),
    ]);
}

// Post-Op Info for Cataracts
function surgeries_postOpInfo_cat(doc) {
    doc.content.push([
        surgeries_getHeader(true),
        surgeries_getTitle(`Cataract Surgery - Post-Operative Information`),
        surgeries_postOpInfo_dayOfSurgery(),
        surgeries_postOpInfo_afterTheSurgery_cat(),
        surgeries_postOpInfo_instructionsToFollow_cat(),
        surgeries_postOpInfo_symptomsToWatchFor(),
    ]);
}

function surgeries_postOpInfo_faceDownRecovery(doc) {
    doc.content.push([
        surgeries_getHeader(true),
        surgeries_getTitle('Face-Down Posturing After Retinal Surgery'),
        {text: `What Is Face-down Posturing After Retinal Surgery?\n\n`, style: 'h2'},
        `After some types of retinal surgery, you will need to keep your head in a face-down position. This is because a gas bubble has been put in your eye. Recovering with your head down allows the bubble to float into the correct position. The bubble holds the retina in place to heal correctly.\n\n`,
        {
            image: surgeries_postOpInfo_faceDownRecovery_img(0),
            alignment: 'center'
        },
        `Your doctor will tell you how long you need to stay face down. It could be anywhere from a few days to a week or more. Over time, your eye fills with its own fluid, and the gas bubble disappears.\n\n`,
        {
            image: surgeries_postOpInfo_faceDownRecovery_img(1),
            alignment: 'center',
        },
        {text: `When seated or lying face down, the bubble floats in the correct position in your eye, puts pressure on the retina and helps it reattach to the back of the eye.\n\n`, italics: true},
        {text: `Important Things to Know About Face-down Recovery:\n\n`, style: 'h2'},
        {
            ul: [
                `You must stay face down at all times, unless your eye surgeon tells you otherwise. This means staying face down when you stand, sit, eat, walk, and sleep. To stay safe, have someone with you when you walk around.`,
                `Your surgery may not work if you do not recover in the recommended position. This is because lying in the wrong position puts pressure on other parts of your eye. That can lead to other eye problems.`, 
                `You cannot fly in an airplane, go to mountains/high altitudes or scuba dive until the gas bubble is gone. Altitude changes can cause your eye pressure to rise. That can lead to problems with the bubble.`,
            ]
        },
        {text: `\nMaking Your Face-down or Sideways Recovery Easier:\n\n`, style: 'h2'},
        {
            ul: [
                {
                    text: [{text: `Sitting:`, bold: true}, ` Fold your arms on a table and lay your forehead on your arms.`]
                },
                {
                    text: [{text: `Lying down:`, bold: true}, ` Lie face down on a pillow; have the recovering side of your face hang off the edge of the bed. This helps reduce pain and keeps pressure off your operated eye.`]
                },
                {
                    text: [{text: `Anytime:`, bold: true}, ` Use special equipment that can make it easier to stay face down or sideways. You can rent or buy equipment such as:`]
                },
                {
                    ul: [
                        {
                            text: [{text: `Face-down chairs.`, bold: true}, `These can adjust to provide support for your head and neck while you sit.`]
                        },
                        {
                            text: [{text: `Tabletop face cradles.`, bold: true}, `These allow you to keep your hands free while sitting at the table.`]
                        },
                        {
                            text: [{text: `Face-down pillows.`, bold: true}, `These have a space cut out for your face to help you sleep more comfortably.`]
                        },
                        {
                            text: [{text: `Face-down mirrors.`, bold: true}, `These are angled to let you see people or objects in front of you. That way you can watch TV or speak directly with visitors.`]
                        },
                    ]
                }
            ]
        },
        {
            text: `\nAsk your ophthalmologist for information about renting or buying face-down recovery equipment.
            
            Face-down video: https://www.youtube.com/watch?v=NIRq8sMMDsU\n\n`
        },
        { // QR Code
            alignment: 'center',
            width: 150,
            image: surgeries_postOpInfo_faceDownRecovery_img(2),
        },
        surgeries_fullPageImage(surgeries_postOpInfo_faceDownRecovery_img(3)),
        surgeries_fullPageImage(surgeries_postOpInfo_faceDownRecovery_img(4)),
    ]);
}

function surgeries_postOpInfo_dayOfSurgery() {
    return [
        `As with any operation, post-operative complications are possible, though rare. To avoid problems, please take care to understand and follow these post-operative instructions.\n\n`,
        {text: `Day of your surgery:\n\n`, style: 'h2'},
        `You will need to have a family member or friend with you to take you home and stay with you for the first 24 hours following the surgery. Once you get home, it is recommended that you rest your eyes and nap. It is important that you do not remove the dressing, this will be removed the next day at your post-operative appointment.\n\n`,
    ];
}

function surgeries_postOpInfo_afterTheSurgery_cat() {
    return [
        {text: `Days after cataract surgery:\n\n`, style: 'h2'},
        `Vision will normally begin to improve within a few days of the surgery. It is normal for your vision to be initially blurry while your eye is adjusting to the new intra-ocular lens. It is also normal for your eye to feel itchy, gritty and to experience mild discomfort for a couple of days. Paracetamol can be taken for pain relief every four to six hours when required. Your glasses prescription will no longer be suitable for the eye that was operated on. You may find it useful to use some chemist readers with a power of +2.50 for near work until you are able to get a new pair of glasses prescribed by an optometrist.\n\n`,
        {text: `Weeks after cataract surgery:\n\n`, style: 'h2'},
        `While everyone is different, the average experience for the weeks following cataract surgery involves a gradual recovery of the eye. Adjustments in vision will occur up to 6-8 weeks after the surgery. We recommend you wait 6-8 weeks following your surgery before you see your optometrist for new glasses.\n\n`
    ];
}

function surgeries_postOpInfo_afterTheSurgery_cp() {
    return [
        {text: `After the surgery:\n\n`, style: 'h2'},

        `The eye may feel sore for a number of weeks after the surgery and it is advisable to plan time off work for this period. This is especially important if you work outdoors or in dry, windy or dusty conditions. Paracetamol can be taken for pain relief every four to six hours when required. It is normal for your eye to weep throughout the first 1 to 2 weeks after the surgery. Your eye will look red for several weeks and your vision may be blurry for a few months. This will gradually get better with time.
        
        If you have severe pain, not relieved by simple painkillers, please contact Pennington Eye Clinic or your doctor for advice.\n\n`,
    ];
}

function surgeries_postOpInfo_afterTheSurgery_verm() {
    return [
        {
            text: [
                {text: `Days after surgery:\n\n`, style: 'h2'},

                `Your eye will feel uncomfortable, gritty, itchy and might appear red or bruised - this is normal for seven to 14 days. Paracetamol can be taken for pain relief every four to six hours. Your eye will take 2-6 weeks to heal, but your vision may continue to improve for several months.\n\n`,

                {text: `At the end of the surgery the doctor may insert a gas bubble or oil into your eye.`, bold: true}, ` It lightly presses against the wall of the eye while the eye heals and you may need to position your head to assist the healing after the surgery. Your doctor will advise what position and for how long if this is required.`, {text: ` The gas bubble will remain in your eye for a few days up to 8 weeks `, bold: true}, `depending on the type of gas used, your vision will be blurry during this time. A second surgery is required to remove oil. If a gas bubble has been used, `, {text: `DO NOT travel by air, use nitrous oxide gas anesthesia or go up to high altitudes`, bold: true}, ` while there is a gas bubble to reduce the risk of extremely high eye pressure.
                
                You will need to wear the plastic eye shield at night for 1 week following your surgery to ensure you do not rub your eye while you are sleeping.\n\n`
            ]
        }
    ];
}

function surgeries_postOpInfo_instructionsToFollow_cat() {
    return [
        {text: `Instructions to follow:\n\n`, style: 'h2'},
        {
            ul: [
                {
                    text: [{text: `Avoid strenuous activities for a one week.`, bold: true}, ` This includes rigorous exercise and heavy lifting.`]
                },
                {
                    text: [{text: `Do not drive for at least 24 hours.`, bold: true}, ` The length of time after cataract surgery before you can drive will differ for each patient - the doctor will inform you when it is safe to resume driving.`]
                },
                {
                    text: [{text: `Use the antibiotic and anti-inflammatory eye drops four times a day for a month or until the drops run out.`, bold: true}, ` These are important to prevent infection and inflammation and ensure proper healing. Instil any drop first and wait at least 2-3 minutes between instilling the next drop. It is also a good idea to apply some pressure to the inner aspect of the lower lid to help aid absorption.`]
                },
                {
                    text: [{text: `Stay away from dust.`, bold: true}, ` Stay away from airborne allergens that could cause irritation. Avoid dusty environments and gardening for the first month after surgery.`]
                },
                {
                    text: [{text: `Wear sunglasses outdoors.`, bold: true}, ` Your eye may be light sensitive for the first 2 weeks.`]
                },
                {
                    text: [{text: `Do not rub your eye.`, bold: true}, ` If you feel you may rub your eye in your sleep then please continue to use the plastic eye shield at night to prevent this.`]
                },
                {
                    text: [{text: `Avoid letting water enter your eye`, bold: true}, ` during the first week after surgery. Keep your eyes closed when showering.`]
                },
                {
                    text: [{text: `Do not swim.`, bold: true}, ` It's best to avoid swimming or hot tubs for a month after surgery. `]
                },
            ]
        },
    ];
}

function surgeries_postOpInfo_instructionsToFollow_cp() {
    return [
        {text: `Instructions to follow:\n\n`, style: 'h2'},
        {
            ul: [
                {
                    text: [{text: `Use the antibiotic and anti-inflammatory eye drops four times a day for a month or until the drops run out.`, bold: true}, ` These are important to prevent infection and inflammation and ensure proper healing. Instil any drop first and wait at least 2-3 minutes between instilling the next drop. It is also a good idea to apply some pressure to the inner aspect of the lower lid to help aid absorption.`]
                },
                {text: `Do not drive for at least 24 hours.`, bold: true},
                {
                    text: [{text: `Avoid strenuous activities for two weeks.`, bold: true}, ` This includes rigorous exercise and heavy lifting.`]
                },
                {
                    text: [{text: `Avoid letting water enter your eye`, bold: true}, ` during the first week after surgery. Keep your eyes closed when showering.`]
                },
                {
                    text: [{text: `Stay away from dust.`, bold: true}, ` Stay away from airborne allergens that could cause irritation. Avoid dusty environments and gardening for the first two weeks after surgery.`]
                },
                {text: `Wear sunglasses outdoors.`, bold: true},
                {
                    text: [{text: `Do not rub your eye.`, bold: true}, ` If you feel you may rub your eye in your sleep then please continue to use the plastic eye shield at night to prevent this.`]
                },
                {
                    text: [{text: `Do not swim.`, bold: true}, ` It's best to avoid swimming or hot tubs for two weeks after surgery.`]
                },
            ]
        },
    ];
}

function surgeries_postOpInfo_instructionsToFollow_verm() {
    return [
        {text: `Instructions to follow:\n\n`, style: 'h2'},
        {
            ul: [
                {
                    text: [{text: `Avoid strenuous activities for two weeks.`, bold: true}, ` This includes rigorous exercise and heavy lifting.`]
                },
                {
                    text: [{text: `Do not drive.`, bold: true}, ` You cannot drive for at least 24 hours after the surgery or until the gas bubble is completely gone. However, the length of time is different for each patient and your doctor will inform you when it is safe to drive on the day after your surgery.`]
                },
                {
                    text: [{text: `Do not fly`, bold: true}, ` while you have the gas in your eye this could be a few days up to 8 weeks after surgery - your doctor will advise you exactly how long.`]
                },
                {
                    text: [{text: `Use the antibiotic and anti-inflammatory eye drops four times a day for a month or until the drops run out.`, bold: true}, ` These are important to prevent infection and inflammation and ensure proper healing. Instil any drop first and wait at least 2-3 minutes between instilling the next drop. It is also a good idea to apply some pressure to the inner aspect of the lower lid to help aid absorption.`]
                },
                {
                    text: [{text: `Stay away from dust.`, bold: true}, ` Stay away from airborne allergens that could cause irritation. Avoid dusty environments and gardening for the first 4 weeks.`]
                },
                {
                    text: [{text: `Wear sunglasses outdoors.`, bold: true}, ` Your eye may be light sensitive for the first 2 weeks.`]
                },
                {
                    text: [{text: `Do not rub your eye.`, bold: true}, ` If you feel you may rub your eye in your sleep then please continue to use the plastic eye shield at night after the first week to prevent this.`]
                },
                {
                    text: [{text: `Avoid letting water enter your eye`, bold: true}, ` during the first week after surgery. Keep your eyes closed when showering.`]
                },
                {
                    text: [{text: `Do not swim.`, bold: true}, ` It's best to avoid swimming or hot tubs for a month after surgery.`]
                },
            ]
        }
    ];
}

function surgeries_postOpInfo_symptomsToWatchFor() {
    return [
        {text: `\nSymptoms to watch for after surgery:\n\n`, style: 'h2'},
        `If you experience any of the following symptoms, please contact A Prof Chan / Dr David Sia:`,
        {
            ul: [
                `Worsening of vision`,
                `Severe and increasing pain that persists despite the use of over-the-counter pain medications `,
                `Nausea and vomiting`,
            ]
        },
        `\nPlease contact the practice (phone 82671700), or A Prof Weng Chan directly (mobile 0421990757) / Dr David Sia directly on (mobile 0422911322).`,
    ]
}
